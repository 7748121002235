import * as React from 'react';

import Layout from '../layouts/layout';

// markup
const IndexPage = () => {
  return (
    <Layout>
      <title>Home Page</title>

      <div className="container">
        <div className="w-52 h-52 bg-primary-dark"> </div>
        <h1 className="h1">UI Style Guide</h1>
        <h3 className="h3">Typography Style</h3>
        <div className="grid grid-cols-3 gap-4 mb-16">
          <div>
            <h1 className="text-7xl font-normal">Aa</h1>
            <h3 className="text-2xl font-normal">Signika Regular</h3>
          </div>
          <div>
            <h1 className="text-7xl font-semibold">Aa</h1>
            <h3 className="text-2xl font-semibold">Signika SemiBold</h3>
          </div>
          <div>
            <h1 className="text-7xl font-bold">Aa</h1>
            <h3 className="text-2xl font-bold">Signika Bold</h3>
          </div>
        </div>

        <h3 className="h3">Heading Styles</h3>
        <div className="grid grid-cols-3 gap-4 mb-16">
          <div>
            <h1 className="h1">Heading 1</h1>
            <h1 className="h2">Heading 2</h1>
            <h1 className="h3">Heading 3</h1>
            <h1 className="h4">Heading 4</h1>
            <h1 className="h5">Heading 5</h1>
            <h1 className="h6">Heading 6</h1>
          </div>
        </div>

        <h3 className="h3">Buttons</h3>
        <div className="grid grid-cols-3 gap-4 mb-16">
          <div>
            <h3 className="text-2xl font-normal">primary button</h3>
            <a className="btn">Button</a>
          </div>
          <div>
            <h3 className="text-2xl font-semibold">secondary button</h3>
            <a className="btn-secondary">Button</a>
          </div>
          <div>
            <h3 className="text-2xl font-bold">CTA</h3>
            <a className="btn-cta">Button</a>
          </div>
        </div>


        <h3 className="h3">Paragraph</h3>
        <div className="grid grid-cols-3 gap-4 mb-16">
          <div>
            <p className="p-xs md:p-sm lg:p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et odio in eros pretium volutpat. Morbi id odio sed nisl egestas porta semper at ante. Proin tortor sapien, porttitor non mi quis, volutpat ullamcorper lorem. Morbi porttitor ullamcorper dignissim. Etiam sit amet est sit amet ante lobortis mollis vitae ut.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
